import React, { Component } from "react"
import { Container, Row, Col, Form } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"
import axios from "axios"

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: '',
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            subject: '',
            message: '',
        }
    }

    handleSubmit(type) {
        if (type === 'contact' && this.state.company === '') {
            alert('Please enter your company!');
            return false;
        } else if (this.state.email === '') {
            alert('Please enter your email!');
            return false;
        } else if (this.state.firstName === '') {
            alert('Please enter your first name!');
            return false;
        } else if (type === 'contact' && this.state.lastName === '') {
            alert('Please enter your last name!');
            return false;
        } else if (type === 'contact' && this.state.phone === '') {
            alert('Please enter your phone!');
            return false;
        }

        axios({
            method: "POST",
            url: "https://getform.io/f/3cdc58b4-588f-4498-804b-63c30fae62e9",
            data: this.state
        }).then((response) => {
            console.log(response);
            alert("Message Sent.");
            this.resetForm()
            const isBrowser = () => typeof window !== "undefined"
            const path = isBrowser() && window.location.replace('/');
            /* if (response.data.status === 'success') {
                alert("Message Sent.");
                this.resetForm()
            } else if (response.data.status === 'fail') {
                if (response.data.error[0]) {
                    alert(response.data.error[0])
                } else {
                    alert("Something went wrong. Please try again later!")
                }
            } */
        })
    }

    resetForm() {
        this.setState({ company: '', firstName: '', lastName: '', email: '', phone: '', subject: '', message: '' })
    }

    render() {
        return (
            <div>
                <Header pageTitle="Contact Us" />
                <section className="py-3 px-md-5 mx-xl-5 mt-3">
                    <Container className="py-3">
                        <h2 className="text-center font-weight-bold text-theme">Contact Us</h2>
                        <div className="heading-titel-line my-3"></div>
                        <Form>
                            <Row>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="company">Company Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company"
                                        placeholder="Enter Your Company Name"
                                        onChange={e => this.setState({ company: e.target.value })} />
                                </Col>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="email">Company Email <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder="Enter Your Company Email"
                                        onChange={e => this.setState({ email: e.target.value })} />
                                </Col>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="firstName">First Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        placeholder="Enter Your First Name"
                                        onChange={e => this.setState({ firstName: e.target.value })} />
                                </Col>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="lastName">Last Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        placeholder="Enter Your Last Name"
                                        onChange={e => this.setState({ lastName: e.target.value })} />
                                </Col>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="phone">Phone <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter Your Phone"
                                        onChange={e => this.setState({ phone: e.target.value })} />
                                </Col>
                                <Col lg="4" md="6" className="my-2">
                                    <label htmlFor="subject">Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        placeholder="Enter Your Phone" 
                                        onChange={e => this.setState({ subject: e.target.value })}/>
                                </Col>
                                <Col lg="12" className="my-2">
                                    <label htmlFor="message">Your Message</label>
                                    <textarea
                                        className="form-control"
                                        id="message"
                                        placeholder="Enter Your Message"  
                                        onChange={e => this.setState({ message: e.target.value })}></textarea>
                                </Col>
                                <Col lg="12" className="my-2">
                                    <button type="button" onClick={() => this.handleSubmit('contact')}
                                        className="form-control btn btn-primary" >SUBMIT</button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </section>
                <Footer />
            </div>
        )
    }
}
export default ContactUs